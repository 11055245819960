import React from "react";
import styled from "styled-components";
import Koordinator from "./Koordinator";
import { KoordinatorenData } from "./data";
import { spacers, colors, breakpoints } from "../styles/variables";
import Title from "./Title";

const KoordinatorenDiv = styled.div`
  background: ${colors.backgroundGrey};
  padding-top: ${spacers.tablet}px;

  > h1 {
    max-width: 964px;
    margin: 0 auto;
    padding: 0 ${spacers.mobile}px ${spacers.tablet}px;

    @media screen and (min-width: ${breakpoints.tablet}px) {
      margin: 0 auto;
      padding: 0 ${spacers.tablet}px ${spacers.desktop}px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: ${spacers.tablet}px ${spacers.mobile}px ${spacers.mobile}px;
    max-width: 1920px;
    margin: 0 auto;
  }

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding-top: ${spacers.desktop}px;
    > div {
      padding: ${spacers.tablet}px;
    }
  }

  @media screen and (min-width: 1024px) {
    > div {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      > div {
        width: 45%;
      }
    }
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    > div {
      padding: ${spacers.desktop}px;
    }
  }
`;

const Koordinatoren: React.FC = () => {
  return (
    <KoordinatorenDiv>
      <Title>Koordinatoren</Title>
      <div>
        {KoordinatorenData.map((koordinator) => (
          <Koordinator
            image={koordinator.image}
            key={koordinator.index}
            name={koordinator.name}
            position={koordinator.position}
            department={koordinator.department}
          />
        ))}
      </div>
    </KoordinatorenDiv>
  );
};

export default Koordinatoren;
