import React from "react";
import styled from "styled-components";
import { colors, spacers } from "../styles/variables";

const TitleStyled = styled.h1`
  font: 24px / 29px "Helvetica Neue Bold";
  color: white;
  margin: -28px -32px 0;
  display: block;
  background: ${colors.red};
  padding: ${spacers.tablet}px ${spacers.desktop}px ${spacers.mobile}px;
`;

const Title: React.FC = ({ children }) => {
  return <TitleStyled>{children}</TitleStyled>;
};

export default Title;
