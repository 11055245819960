import * as React from "react";

import Page from "../components/Page";
import DefaultLayout from "../layouts/default";
import Landing from "./Landing";
import IntroText from "./IntroText";
import InfoText from "./InfoText";
import BerlinBanner from "../components/BerlinBanner";
import Koordinatoren from "../components/Koordinatoren";
import Sponsoren from "../components/Sponsoren";

const IndexPage = () => (
  <DefaultLayout>
    <Page>
      <div id="Home"></div>
      <Landing />
      <div id="Workshop"></div>
      <IntroText />
      <div id="WorkshopSG"></div>
      <InfoText />
      <BerlinBanner />
      <div id="Koordinatoren"></div>
      <Koordinatoren />
      <div id="Sponsoren"></div>
      <Sponsoren />
    </Page>
  </DefaultLayout>
);

export default IndexPage;
