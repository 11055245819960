import React from "react";
import styled from "styled-components";
import { spacers, colors } from "../styles/variables";

interface ButtonLinkProps {
  url: string;
}

const LinkStyle = styled.a`
  margin: ${spacers.tablet}px 0;
  font: 24px / 29px "Helvetica Neue Bold";
  border: 1px solid ${colors.text};
  border-radius: 3px;
  color: ${colors.text};
  text-decoration: none;
  display: block;
  width: auto;
  text-align: center;
  width: 250px;
  padding: 11px;
  transition: background 300ms;

  &:hover {
    background: ${colors.red};
    border-color: ${colors.red};
    color: white;
  }
`;

const ButtonLink: React.FC<ButtonLinkProps> = ({ url }) => {
  return (
    <LinkStyle target="_blank" href={url}>
      Anmeldung
    </LinkStyle>
  );
};

export default ButtonLink;
