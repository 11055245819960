import React from "react";
import Paragraph from "../components/Paragraph";
import LongText from "../components/LongText";
import LeadHeading from "../components/LeadHeading";
import { colors } from "../styles/variables";
import ButtonLink from "../components/ButtonLinkContent";

const InfoText: React.FC = () => {
  return (
    <LongText
      background={colors.backgroundPink}
      titel={"Workshop St.Gallen 2024"}
    >
      <LeadHeading>Programm St.Gallen 2024</LeadHeading>
      {/* <Paragraph>

        Vortragszeiten bleiben für die virtuelle Durchführung gleich
      </Paragraph> */}
      {/* <Paragraph>Demnächst verfügbar</Paragraph> */}
      {/*<Paragraph>
        <a href="/docs/Programm_Schweiz_2023.pdf">Programm PDF</a>
      </Paragraph>*/}
      <Paragraph>
        <a href="/docs/HICC2024_Flyer__.pdf">Flyer PDF</a>
      </Paragraph>

      <LeadHeading>Veranstaltungsdatum</LeadHeading>
      <Paragraph>26. / 27. April 2024</Paragraph>
      <LeadHeading>Veranstaltungsort</LeadHeading>
      <Paragraph>
        Kantonsspital St. Gallen <br/>
        Haus 39 <br/>
        Rorschacher Str. 226 <br/>
        9016 St. Gallen
        <br/>
        <br/>
        Bitte beachten Sie, dass sich das Haus 39 nicht unmittelbar auf dem Areal des Kantonsspitals befindet.<br/>
        <br/>

        Öffentlicher Verkehr <br/>
        Ab Bahnhof St.Gallen ist das Haus 39 (Bushaltestelle «Grütlistrasse») mit den Bussen der städtischen
        Verkehrsbetriebe der Linie 1 (Stephanshorn) und der Linie 2 (Guggeien) erreichbar.<br/><br/>

        Auto<br/>
        Mit dem Auto erreichen Sie das Haus 39 über die Autobahnausfahrt «St.Fiden/Spitäler». Es steht eine begrenzte
        Anzahl an Tiefgaragenplätzen im 1. UG zur Verfügung. Das 2. und 3. UG steht Dauermietern zur Verfügung.
        Alternativ können Sie die Parkplätze
        beim Paul Grüninger Stadion benutzen. <br/><br/>
        <a href="https://www.kssg.ch/fuer-patienten-besucher/informationen-anreise/anfahrt-arealplaene">Arealpläne</a>
        {/*<a href="/docs/hicc_anreise.pdf">Anreise</a>*/}
        {/* wird virtuell durchgeführt */}
      </Paragraph>
      <LeadHeading>Wissenschaftlicher Ausrichter</LeadHeading>
      <Paragraph>
        Haemostasis in Critical Care GmbH
        <br />
        mit den wissenschaftlichen Leitern:
        <br />
        Prof. Dr. W. Korte, FAMH
        <br />
        Dr. L. Graf, FAMH
      </Paragraph>

      <LeadHeading>Veranstalter</LeadHeading>
      <Paragraph>
        Haemostasis in Critical Care GmbH
        <br />
        c/o Zentrum für Labormedizin St. Gallen
        <br />
        Haemostase- & Haemophilie-Zentrum
        <br />
        Tel.: <a href="tel:+41585809208">+41 58 580 92 08</a>
        <br />
        Fax: +41 58 580 95 30
        <br />
        <a href="mailto:jacqueline.fiore@zlmsg.ch">jacqueline.fiore@zlmsg.ch</a>
      </Paragraph>

      <LeadHeading>Teilnahmegebühren St. Gallen</LeadHeading>
      <Paragraph>
        Medizinstudenten gratis
        <br />
        Mitglieder GTH € 150,00
        <br />
        Nichtmitglieder € 190,00
        <br />
        <br /> Die Teilnahmegebühren beinhalten die Workshop-Teilnahme und
        Tagungsunterlagen
        <br />
        <br />
        Bitte beachten Sie, dass die Teilnehmerzahl begrenzt ist.
        <br />
        <br />
        Bei Fragen wenden Sie sich bitte an: <br />
        MCI | Germany – Berlin MCI Deutschland GmbH
        <br /> Markgrafenstraße 56
        <br /> 10117 Berlin,
        <br />
        <br /> Deutschland T: <a href="tel:+493020459090">+49 30 20 45 90 90</a>
        <br /> F: +49 30 20 45 95 0
        <br />
        <a href="mailto:hicc@mci-group.com">hicc@mci-group.com</a>
      </Paragraph>

      <LeadHeading>Zertifizierung</LeadHeading>
      <Paragraph>
        Schweizerische Gesellschaft für Chirurgie - 6 Credits <br />
        Schweizerische Gesellschaft für Anästhesiologie und Perioperative Medizin -  8 Credits <br />
        Schweizerische Gesellschaft für Hämatologie - 6 Credits <br />
        Verband der medizinischen Laboratorien der Schweiz - 6 Credits <br />
        Schweizerische Gesellschaft für Intensivmedizin  - 8 Credits <br />
        Schweizerische Gesellschaft für Allgemeine Innere Medizin - 6 Credits
      </Paragraph>


      {/* <LeadHeading>Sponsoren</LeadHeading>
      <Paragraph>
        Alexion Pharma GmbH
        <br />
        Axon Lab AG
        <br />
        Bayer (Schweiz) AG
        <br />
        CSL Behring AG
        <br />
        Mitsubishi Tanabe Phama GmbH
        <br />
        Novo Nordisk Pharma AG
        <br />
        Roche Pharma (Schweiz) AG
        <br />
        Swedish Orphan Biovitrum AG
        <br />
        Takeda Pharma AG
      </Paragraph> */}
      <Paragraph>
        <ButtonLink
          url={"https://www.mci-online.org/secure/conreg_hicc2024/"}
        />
      </Paragraph>
    </LongText>
  );
};

export default InfoText;
