import React from "react";
import styled from "styled-components";

interface SponsorProps {
  name: string;
  url: string;
  image: string;
}

const SponsorDiv = styled.a<SponsorProps>`
  text-align: center;
  display: block;
  width: 100%;
  margin: 0 auto;
  max-width: 450px;
  height: 450px;
  margin-bottom: 60px;

  img {
    height: 75px;
  }
`;

const Sponsor: React.FC<SponsorProps> = ({ name, image, url }) => {
  return (
    <SponsorDiv href={url} target="_blank">
      <div>
        <img src={image} alt={name} />
      </div>
    </SponsorDiv>
  );
};

export default Sponsor;
