import React from "react";
import styled from "styled-components";
import NameTitle from "./NameTitle";
import SmallText from "./SmallText";
import { spacers, breakpoints } from "../styles/variables";

interface KoordinatorProps {
  index: number;
  name: string;
  position: string;
  department: string;
  image: string;
}

const KoordinatorDiv = styled.div<KoordinatorProps>`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  max-width: 450px;
  height: 450px;
  margin-bottom: ${spacers.tablet}px;

  > div {
    position: relative;
    background: #fff;
    top: 100px;
    margin: 0 auto;
    width: 100%;
    max-width: 350px;
    height: 350px;

    > div {
      padding: 10px ${spacers.tablet}px ${spacers.mobile}px;
      position: relative;
      bottom: 0;
    }
  }

  p i {
    font: 20px / 24px "Helvetica Neue Italic";
    width: 100%;
  }

  p {
    margin: 10px 0;
  }

  img {
    margin-top: -100px;
    width: 260px;
  }
`;

const Koordinator: React.FC<KoordinatorProps> = ({
  index,
  name,
  position,
  department,
  image,
}) => {
  return (
    <KoordinatorDiv key={index}>
      <div>
        <img src={image} />
        <NameTitle>{name}</NameTitle>
        <div>
          {position !== "" ? (
            <p>
              <i dangerouslySetInnerHTML={{ __html: position }}></i>
            </p>
          ) : (
            <p>
              <i>{position}</i>
            </p>
          )}
          <SmallText>{department}</SmallText>
        </div>
      </div>
    </KoordinatorDiv>
  );
};

export default Koordinator;
